<template>
  <b-modal
    visible
    centered
    title="Экспорт полисов"
    size="md"
    hide-footer
    @hidden="onClose"
  >
    <custom-scrollbar>
      <div class="policy-export-inner">
        <div
          class="crm-form-row crm-form-row-select"
          :class="{'mb-4': errors.companyId}"
        >
          <div class="crm-form-field">
            <div class="crm-form-block">
              <v-select
                v-model="exportData.companyId"
                :reduce="company => company.id"
                :options="insuranceCompanies"
                placeholder="Выберите компанию"
                label="title"
                @input="changeCompany"
              />
            </div>
          </div>
          <div
            v-if="errors.companyId"
            class="validation-errors"
          >
            <span
              class="validation-error-text"
            >
              {{ errors.companyId[0] }}
            </span>
          </div>
        </div>

        <div
          class="crm-form-row crm-form-row-select"
          :class="{'mb-4': errors.companyId}"
        >
          <div class="crm-form-field">
            <div class="crm-form-block">
              <v-select
                v-model="exportData.programId"
                :reduce="program => program.id"
                :options="insurancePrograms"
                placeholder="Выберите программу"
                label="name"
                @input="changeProgram"
              />
            </div>
          </div>
          <div
            v-if="errors.programId"
            class="validation-errors"
          >
            <span
              class="validation-error-text"
            >
              {{ errors.programId[0] }}
            </span>
          </div>
        </div>

        <div class="crm-form-row crm-form-row-select">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <v-select
                v-model="exportData.subprogramId"
                :reduce="subprogram => subprogram.id"
                :options="insuranceSubprograms"
                placeholder="Выберите подпрограмму"
                label="name"
              />
            </div>
          </div>
          <div
            v-if="errors.subprogramId"
            class="validation-errors"
          >
            <span
              class="validation-error-text"
            >
              {{ errors.subprogramId[0] }}
            </span>
          </div>
        </div>

        <div class="crm-form-row">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Период
              </p>
              <div class="crm-wrapper-radio">
                <base-radio-button
                  v-model="exportData.periodMode"
                  :name="1"
                >
                  по дате загрузки
                </base-radio-button>

                <base-radio-button
                  v-model="exportData.periodMode"
                  :name="0"
                >
                  по сроку действия полисов
                </base-radio-button>

                <base-radio-button
                  v-model="exportData.periodMode"
                  :name="2"
                >
                  по дате консультаций
                </base-radio-button>

                <base-checkbox
                  v-model="isUsersCount"
                  class="crm-checkbox"
                >
                  Расчет кол-ва физ. лиц
                </base-checkbox>
                <div
                  v-if="isUsersCount"
                >
                  <div
                    class="w-75 d-flex justify-content-between align-items-center"
                  >
                    <base-date-picker
                      v-model="dateUserCount"
                      :clearable="false"
                      placement="top-start"
                    />
                    <b-overlay
                      :show="isLoadingCalc"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      @hidden="onHidden"
                    >
                      <b-button
                        ref="button"
                        class="ml-3"
                        :disabled="isLoadingCalc"
                        variant="primary"
                        :type="$const.PRIMARY_BUTTON"
                        size="sm"
                        @click="sendDateUserCount"
                      >
                        Рассчитать
                      </b-button>
                    </b-overlay>
                  </div>
                  <div class="calc-wrapper">
                    <div
                      v-if="userCount"
                      class="user-count"
                    >
                      <span>
                        Кол-во найденных физ. лиц:
                      </span>
                      <span class="font-weight-bold">
                        {{ userCount }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="crm-form-row">
          <div
            class="crm-form-field crm-form-field-date"
            :class="{'mb-4': errors.dateFrom}"
          >
            <div
              class="crm-form-block"
              :style="{ width: '150px' }"
            >
              <base-date-picker
                v-model="exportData.dateFrom"
                :clearable="false"
                placement="top-start"
              />
            </div>
            <div
              v-if="errors.dateFrom"
              class="validation-errors"
            >
              <span
                class="validation-error-text"
              >
                {{ errors.dateFrom[0] }}
              </span>
            </div>
          </div>
          <div class="crm-form-field-divider" />
          <div
            class="crm-form-field crm-form-field-date"
            :class="{'mb-4': errors.dateFrom}"
          >
            <div
              class="crm-form-block"
              :style="{ width: '150px' }"
            >
              <base-date-picker
                v-model="exportData.dateTo"
                :clearable="false"
                placement="top-start"
              />
            </div>
            <div
              v-if="errors.dateTo"
              class="validation-errors"
            >
              <span
                class="validation-error-text"
              >
                {{ errors.dateTo[0] }}
              </span>
            </div>
          </div>
        </div>

        <div class="crm-form-row">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Выгрузить информацию:
              </p>
              <div class="crm-wrapper-checkbox">
                <!-- <base-checkbox
                v-model="exportData.withApplication"
                class="crm-checkbox"
              >
                Наличие приложения
              </base-checkbox>
              <base-checkbox
                v-model="exportData.withConsultations"
                class="crm-checkbox"
              >
                Наличие консультаций
              </base-checkbox> -->
                <base-checkbox
                  v-model="exportData.withActivation"
                  class="crm-checkbox"
                >
                  Активация полиса
                </base-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="crm-wrapper-buttons">
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            :loading="isLoading"
            @click="onExport"
          >
            Экспортировать
          </b-button>
        </div>
      </div>
    </custom-scrollbar>
  </b-modal>
</template>

<script>
import { formatISO } from '@evd3v/date-fns';

import {
  errorTypes,
  defaultErrorField,
} from '@/validation/zod/main/main.validation.js';

import { insurancePolicyExportSchema } from '@/validation/zod/insurance/insurance.validation.js';

import { showValidationErrorMessage } from '@/helpers/messages';

import {
  BaseRadioButton,
  BaseCheckbox,
  BaseDatePicker,
} from '@/components/base';
import { mapGetters } from 'vuex';

import * as types from '@/store/types';

export default {
  name: 'InsurancePolicyExport',
  components: {
    BaseRadioButton,
    BaseCheckbox,
    BaseDatePicker,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      exportData: {
        periodMode: 1,
        dateFrom: null,
        dateTo: null,
        // withApplication: false,
        // withConsultations: false,
        withActivation: false,
        companyId: null,
        programId: null,
        subprogramId: null,
      },
      insuranceData: [],
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      isUsersCount: false,
      dateUserCount: null,
      userCount: null,
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoadingCalc: types.IS_LOADING_CALC,
    }),
  },
  watch: {
    isUsersCount() {
      if (this.isUsersCount === false) {
        this.dateUserCount = null;
        this.userCount = null;
      }
    },
  },
  async created() {
    const data = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceData = data;
    this.insuranceCompanies = data.map(({ company: { id, title } }) => ({ id, title }));
  },
  methods: {
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
    onClose() {
      this.$emit('input', false);
    },
    changeCompany() {
      this.exportData.programId = null;
      this.exportData.subprogramId = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];

      if (!this.exportData.companyId) return;

      const companyObject = this.insuranceData.filter(({ company: { id } }) => id === this.exportData.companyId)[0];
      const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
      this.insurancePrograms = programs;
    },
    async sendDateUserCount() {
      this.userCount = await this.$store.dispatch(this.$types.CALC_USER_COUNT_TODATE, new Date(this.dateUserCount).toISOString());
    },
    changeProgram() {
      this.exportData.subprogramId = null;
      this.insuranceSubprograms = [];

      if (!this.exportData.programId) return;
      const companyObject = this.insuranceData.filter(({ company: { id } }) => id === this.exportData.companyId)[0];
      const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === this.exportData.programId)[0];
      this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
    },
    async onExport() {
      this.errors = {};

      const validationResult = insurancePolicyExportSchema.safeParse({
        companyId: this.exportData.companyId,
        programId: this.exportData.programId,
        subprogramId: this.exportData.subprogramId,
        dateFrom: this.exportData.dateFrom,
        dateTo: this.exportData.dateTo,
      });

      if (!validationResult.success) {
        validationResult.error.issues.forEach((issue) => {
          let errorMsg = issue.message;

          if (errorMsg === errorTypes.date_null
              || errorMsg === errorTypes.num_null
          ) {
            errorMsg = defaultErrorField;
          }

          const field = issue.path[0];
          this.$set(this.errors, field, [errorMsg]);
        });
        showValidationErrorMessage();
      } else {
        this.isLoading = true;
        try {
          const {
            companyId,
            dateFrom,
            dateTo,
            ...data
          } = this.exportData;

          const requestObject = {
            ...data,
            dateFrom: dateFrom ? formatISO(dateFrom) : undefined,
            dateTo: dateTo ? formatISO(dateTo.setHours(23, 59, 0, 0)) : undefined,
            companyId: companyId || undefined,
          };

          await this.$store.dispatch(this.$types.INSURANCE_POLICY_EXPORT, requestObject);
        } catch (error) {
          console.dir(error);
          console.error(error);
        } finally {
          this.isLoading = false;
          this.onClose();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .policy-export {
//   height: 600px;
//   max-height: calc(100vh - 200px);
//   margin-right: -15px;
// }

.policy-export-inner {
  width: 100%;
  padding-right: 15px;
  box-sizing: border-box;
}

::v-deep.crm-wrapper-radio {
  display: flex;
  flex-direction: column;

  .custom-radio:not(:last-child) {
    margin-bottom: 10px;
  }
}
.crm-wrapper-checkbox {
  display: flex;
  flex-direction: column;
}
.crm-checkbox {
  font-size: 14px;
  line-height: 17px;
}
.crm-checkbox + .crm-checkbox {
  margin-top: 10px;
}

.crm-form-row {
  display: flex;
  position: relative;

  .crm-form-field {
    flex: 1;
    position: relative;

    &.crm-form-field-date {
      flex: initial;
    }
  }

  .crm-form-field-divider {
    align-self: center;
    width: 30px;
    height: 1px;
    background-color: #323232;
    margin: 0 10px;
  }
}

.crm-form-row + .crm-form-row {
  margin-top: 30px;
}

.crm-form-row-select + .crm-form-row-select {
  margin-top: 20px;
}

.crm-wrapper-buttons {
  margin-top: 30px;
}

::v-deep.crm-radio-input:checked + .crm-radio-text {
  color: $blue;
}

.user-count {
  margin-left: 10px;
}

.calc-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
