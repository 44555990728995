/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

export const insurancePolicyExportSchema = z.object({
  companyId: z.number().nonnegative(),
  programId: z.number().nonnegative(),
  subprogramId: z.number().nonnegative(),
  dateFrom: z.date(),
  dateTo: z.date(),
});
